var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"system"},[_vm._m(3),_c('div',{staticClass:"content-div"},_vm._l((_vm.sysArr),function(item){return _c('div',{key:item.id,staticClass:"img-text"},[_c('img',{attrs:{"src":item.img,"alt":""}}),_c('p',{staticClass:"name"},[_vm._v(_vm._s(item.name))])])}),0)]),_c('div',{staticClass:"main-products"},[_vm._m(4),_c('div',{staticClass:"img-div"},[_c('div',{ref:"mySwiper",staticClass:"swiper-container",attrs:{"id":"msSwiper"}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.msArr),function(item){return _c('div',{key:item,staticClass:"swiper-slide my-swiper-slide"},[_c('img',{attrs:{"src":item,"alt":""}})])}),0),_c('div',{staticClass:"swiper-pagination"})])])]),_vm._m(5)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"abstract"},[_c('img',{attrs:{"src":require("../../assets/imgs/smart.png"),"alt":""}}),_c('div',{staticClass:"text"},[_c('p',{staticClass:"title"},[_vm._v("数字城市")]),_c('p',{staticClass:"subTitle"},[_vm._v("Digital City")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"smart-hotel"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("智慧酒店")]),_c('p',{staticClass:"subTitle"},[_vm._v("smart hotel")]),_c('p',{staticClass:"content"},[_vm._v("智慧酒店系统基于微信平台开发的创新型智慧酒店综合服务管理平台。它包括酒店客户下单、入住、酒店设备智能化交互、酒店客房服务等。使用该系统将大大降低酒店管理运营成本，提高酒店服务质量，给客户带来更舒适、时尚、便捷和科技感的入住体验。")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background-color":"#F4F8FF"}},[_c('div',{staticClass:"process"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("智慧酒店应用流程")]),_c('p',{staticClass:"content"},[_vm._v("酒店通过微信功能完成从客户获取、入住、酒店设备控制、客房服务等酒店管理、酒店服务等功能")])]),_c('img',{attrs:{"src":require("../../assets/imgs/hotelprocess.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("智慧酒店系统功能")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("主要产品")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hotel-case"},[_c('div',{staticClass:"text"},[_c('div',{staticClass:"line"}),_c('p',{staticClass:"title"},[_vm._v("酒店案例")])]),_c('div',{staticClass:"content-div"},[_c('div',{staticClass:"blue"},[_c('img',{attrs:{"src":require("../../assets/imgs/hc1.png"),"alt":""}})]),_c('div',{staticClass:"hui"}),_c('div',{staticClass:"hui"},[_c('img',{attrs:{"src":require("../../assets/imgs/hc2.png"),"alt":""}})]),_c('div',{staticClass:"blue"}),_c('div',{staticClass:"blue"}),_c('div',{staticClass:"hui"},[_c('img',{attrs:{"src":require("../../assets/imgs/hc3.png"),"alt":""}})]),_c('div',{staticClass:"hui"}),_c('div',{staticClass:"blue"},[_c('img',{attrs:{"src":require("../../assets/imgs/hc4.png"),"alt":""}})])])])
}]

export { render, staticRenderFns }