<template>
	<div class="container">
		<div class="abstract">
			<img src="../../assets/imgs/smart.png" alt="">
			<div class="text">
				<p class="title">数字城市</p>
				<p class="subTitle">Digital City</p>
			</div>
		</div>
		
		<div class="smart-hotel">
			<div class="text">
				<div class="line"></div>
				<p class="title">智慧酒店</p>
				<p class="subTitle">smart hotel</p>
				<p class="content">智慧酒店系统基于微信平台开发的创新型智慧酒店综合服务管理平台。它包括酒店客户下单、入住、酒店设备智能化交互、酒店客房服务等。使用该系统将大大降低酒店管理运营成本，提高酒店服务质量，给客户带来更舒适、时尚、便捷和科技感的入住体验。</p>
			</div>
		</div>
		
		<div style="background-color: #F4F8FF;">
			<div class="process">
				<div class="text">
					<div class="line"></div>
					<p class="title">智慧酒店应用流程</p>
					<p class="content">酒店通过微信功能完成从客户获取、入住、酒店设备控制、客房服务等酒店管理、酒店服务等功能</p>
				</div>
				<img src="../../assets/imgs/hotelprocess.png" alt="">
			</div>
		</div>
		
		<div class="system">
			<div class="text">
				<div class="line"></div>
				<p class="title">智慧酒店系统功能</p>
			</div>
			<div class="content-div">
				<div class="img-text" v-for="item of sysArr" :key="item.id">
					<img :src="item.img" alt="">
					<p class="name">{{item.name}}</p>
				</div>
			</div>
		</div>
		
		<div class="main-products">
			<div class="text">
				<div class="line"></div>
				<p class="title">主要产品</p>
			</div>
			
			<div class="img-div">
				<div ref="mySwiper" class="swiper-container" id="msSwiper"  >
				  <div class="swiper-wrapper">
				    <div class="swiper-slide my-swiper-slide" v-for="item of msArr" :key="item">
						<img :src="item" alt="">
						
					</div>
				  </div>
				   <div class="swiper-pagination"></div>
				
				</div>
				
			</div>
		</div>
		
		<div class="hotel-case">
			<div class="text">
				<div class="line"></div>
				<p class="title">酒店案例</p>
			</div>
			<div class="content-div">
				<div class="blue">
					<img src="../../assets/imgs/hc1.png" alt="">
				</div>
				<div class="hui"></div>
				<div class="hui">
					<img src="../../assets/imgs/hc2.png" alt="">
				</div>
				<div class="blue"></div>
				<div class="blue"></div>
				<div class="hui"><img src="../../assets/imgs/hc3.png" alt=""></div>
				<div class="hui"></div>
				<div class="blue"><img src="../../assets/imgs/hc4.png" alt=""></div>
			</div>
		</div>
	</div>
</template>

<script>
	import Swiper from 'swiper'
	import "swiper/css/swiper.css";
	export default {
		
		data() {
			return {
				sysArr: [
					{
						id: '1',
						img:require('@/assets/imgs/hs1.png'),
						name: '客房门铃'
					},
					{
						id: '2',
						img:require('@/assets/imgs/hs2.png'),
						name: '音乐控制'
					},
					{
						id: '3',
						img:require('@/assets/imgs/hs3.png'),
						name: '睡眠监测'
					},
					{
						id: '4',
						img:require('@/assets/imgs/hs4.png'),
						name: '智能卫浴'
					},
					{
						id: '5',
						img:require('@/assets/imgs/hs5.png'),
						name: '门锁控制'
					},
					{
						id: '6',
						img:require('@/assets/imgs/hs6.png'),
						name: '温度控制'
					},
					{
						id: '7',
						img:require('@/assets/imgs/hs7.png'),
						name: '窗帘控制'
					},
					{
						id: '8',
						img:require('@/assets/imgs/hs8.png'),
						name: '情景控制'
					},
					{
						id: '9',
						img:require('@/assets/imgs/hs9.png'),
						name: '影音控制'
					},
					{
						id: '10',
						img:require('@/assets/imgs/hs10.png'),
						name: '灯光控制'
					},
					{
						id: '11',
						img:require('@/assets/imgs/hs11.png'),
						name: '酒店服务'
					},
					{
						id: '12',
						img:require('@/assets/imgs/hs12.png'),
						name: '微信控制'
					},
				],
				msArr:[
					require('@/assets/imgs/ms1.png'),
					require('@/assets/imgs/ms2.png'),
					require('@/assets/imgs/ms3.png'),
					require('@/assets/imgs/ms4.png'),
					require('@/assets/imgs/ms5.png'),
					require('@/assets/imgs/ms6.png'),
					require('@/assets/imgs/ms7.png'),
					require('@/assets/imgs/ms8.png'),
				]
			}
		},
		
		methods: {
			initSwiper(){
			  // eslint-disable-next-line
			  let vueComponent=this//获取vue组件实例
			  //一个页面有多个swiper实例时，为了不互相影响,绑定容器用不同值或变量绑定
			  this.currentSwiper = new Swiper('#msSwiper', {
				
				autoHeight:'true',//开启自适应高度,容器高度由slide高度决定
				//分页器
				pagination: {
				  el: '.swiper-pagination',
				  clickable:true,//分页器按钮可点击
				  type: 'progressbar'
				},
				
				//导航器
				// navigation: {
				//   nextEl: '.swiper-button-next',
				//   prevEl: '.swiper-button-prev',
				// },
			   
				slidesPerView: 1, //视口展示slide数1
				// spaceBetween: 12,
				centeredSlides: true,
				loop: true,
				
			  })
					
			}
		},
		
		mounted() {
			this.initSwiper()
		}
	}
</script>

<style scoped>
.abstract {
	position: relative;
}

.abstract img {
	width: 100%;
}

.abstract .text {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: #fff;
	text-align: center;
}

.abstract .text .title {
	font-size: 40px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
}

.abstract .text .subTitle {
	font-size: 18px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #FFFFFF;
	text-transform: uppercase;
	margin-top: 43px;
}

.line {
	width: 25px;
	height: 3px;
	background: #184BAB;
}

.title {
	height: 24px;
	font-size: 24px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.subTitle {
	height: 12px;
	font-size: 12px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #666666;
	text-transform: uppercase;
}

.smart-hotel {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 69px 271px 110px;
	
}

.smart-hotel .text {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.smart-hotel .text .subTitle {
	margin: 7px 0 34px;
}
.smart-hotel .text .content {
	width: 644px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	line-height: 20px;
	text-align: center;
}

.process {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 100px 91px 70px;
	
}

.process .text .line {
	height: 4px;
}

.process .text .content {
	height: 14px;
	font-size: 14px;
	font-family: SourceHanSansCN-Normal, SourceHanSansCN;
	font-weight: 400;
	color: #333333;
	margin: 19px 0 36px;
}

.system {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 72px 104px 77px;
}

.system .text .line {
	height: 4px;
}

.content-div {
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	text-align: center;
	margin-top: 59px;
}

.content-div .img-text {
	margin-bottom: 33px;
}

.content-div .img-text:not(:nth-child(6n)) {
	margin-right: 73px;
}

.content-div .img-text .name {
	height: 14px;
	font-size: 14px;
	font-family: SourceHanSansCN-Medium, SourceHanSansCN;
	font-weight: 500;
	color: #333333;
}

.main-products {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
}

.main-products .text{
	
	padding-left: 104px;
}

.main-products .text .line {
	height: 4px;
}

.main-products .img-div {
	margin-top: 70px;
}

.hotel-case {
	width: 1200px;
	margin: 0 auto;
	box-sizing: border-box;
	padding: 108px 70px 110px;
}

.hotel-case .text .line {
	height: 4px;
}

.hotel-case .content-div {
	display: flex;
	flex-wrap: wrap;
	margin-top: 60px;
}

.hotel-case .content-div div {
	height: 190px;
	width: 265px;
}

.swiper-container {
	height: 443px;
	width: 986px;
}

.swiper-slide {
	height: 404px;
	width: 986px;
}

.swiper-pagination {
	top: 437px;
	left: 50%;
	transform: translate(-50%,0);
	width: 165px;
}

.hotel-case .content-div .blue {
	background-color: #245ECB;
}

.hotel-case .content-div .hui {
	background: #F4F8FF;
}
.hotel-case .content-div img {
	width: 100%;
	height: 100%;
}
</style>